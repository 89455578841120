<template>
    <div class="mgl">
        <pre>{{ monitoredData.gl }}</pre>
    </div>
</template>

<script setup>
    import { inject, onMounted, reactive } from 'vue';

    const { renderer } = inject('renderer');

    const monitoredData = reactive({ gl: '' });
    onMounted(() => {
        const info = renderer.info;
        info.autoReset = false;
        setInterval(() => {
            requestAnimationFrame(() => {
                monitoredData['gl'] = JSON.stringify({
                    calls: info.render.calls,
                    triangles: info.render.triangles,
                    textures: info.memory.textures,
                    geometries: info.memory.geometries,
                    programs: info.programs.length,
                })
                    .replace(/[{}]/g, '')
                    .split(',')
                    .join('\n');
            });
        }, 1000);
    });
</script>

<style lang="scss" scoped>
    .mgl {
        position: fixed;
        top: 8px;
        right: 8px;
        max-width: 280px !important;
        height: auto;
        background-color: #0000006b;
        padding: 8px;
        border-radius: 6px;
        z-index: 10;
        font-size: 1rem;
        font-family: 'Consolas', 'Courier New', monospace;
        color: hsl(230, 7%, 70%);
    }
</style>
