<template>
    <MonitorGLWidget v-if="params.monitorGL" />

    <Box ref="refBox" :image="box?.image" :color="box?.color" />
    <Pack ref="refPack" :image="box?.pack.image" :color="box?.pack.color" />

    <Cards ref="refCards" :cards="cards" :cardIndex="cardIndex" :colorPalette="colorPalette" />

    <Foreground :theme="colorPaletteIndex" :dimmed="fanatics.lightsDown.value" />
    <GLTextInit />
</template>

<script setup>
    import { useEnvVar } from '#imports';
    import {
        inject,
        nextTick,
        onMounted,
        onUnmounted,
        provide,
        reactive,
        ref,
        shallowRef,
        watch,
    } from 'vue';

    import { urlSearchParams } from '@resn/gozer-misc';
    import { usePane, useRaf, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import Box from './Box.vue';
    import Cards from './Cards/Cards.vue';
    import Foreground from './Foreground/index.vue';
    import GLTextInit from './GLTextInit.vue';
    import MonitorGLWidget from './MonitorGLWidget.vue';
    import Pack from './Pack.vue';
    import { Complete } from '~/api/constants/messages';
    import {
        StepBoxReveal,
        StepCardsReveal,
        StepIdle,
        StepPackReveal,
    } from '~/api/constants/steps';
    import { useSandBox } from '~/composables/useSandbox';
    // import { useSceneComposer } from '~/composables/useSceneComposer';
    import { useSceneComposerPmndrs } from '~/composables/useSceneComposerPmndrs';
    import useThreeMainScene from '~/composables/useThreeMainScene';
    import { useThreeOrthoObject } from '~/composables/useThreeOrthoObject';
    import { useAudio } from '~/providers/AudioProvider';
    import { useColorPalette } from '~/providers/ColorPaletteProvider';
    import { useFanatics } from '~/providers/FanaticsProvider';
    import { useThreePrerender } from '~/providers/ThreePrerender';

    const { renderer, orthoCamera, camera, scene } = inject('renderer');

    const fanatics = useFanatics();
    const { addSceneToPrerender } = useThreePrerender();
    const { step, cardIndex, cards, colorPaletteIndex, box, sceneOffset, events } = fanatics;
    const isSandbox = useSandBox();
    const colorPalette = useColorPalette();
    const audio = useAudio();

    const sceneComposer = useSceneComposerPmndrs({ renderer, bloom: true });

    const componentTypes = [
        { id: 'box', component: Box },
        { id: 'pack', component: Pack },
        { id: 'cards', idAnims: 'card', component: Cards },
    ];
    const components = shallowRef([]);

    const refBox = ref(null);
    const refPack = ref(null);
    const refCards = ref(null);

    const params = reactive({
        orbit: Boolean(urlSearchParams.get?.('orbit')),
        helpers: Boolean(urlSearchParams.get?.('helpers')),
        monitorGL: !isSandbox
            ? useEnvVar('ENABLE_MONITOR_GL')
            : Boolean(urlSearchParams.get?.('monitorGL')),
    });

    const sceneCamera = shallowRef(null);
    provide('sceneCamera', sceneCamera);

    useThreeOrthoObject(null, { name: 'OrthographicRoot', root: true });

    onMounted(() => {
        events.on(Complete, onComplete);

        addSceneToPrerender({ id: 'mainScene', scene });

        window.fanatics = fanatics;

        if (!audio.disableAmbientAudio.value) {
            audio.play('cardStaticState', { unique: false, ignoreUnlocked: true });
        }
    });

    const setLightsDown = (val) => {
        if (audio.disableAmbientAudio.value) return;

        if (val) {
            audio.pause('cardStaticState', { fade: true });
        } else {
            audio.play('cardStaticState', { fade: true });
        }
    };

    watch(() => fanatics.lightsDown.value, setLightsDown);

    onUnmounted(() => {
        events.off(Complete, onComplete);
    });

    const {
        renderFn: renderScene,
        getAnimations,
        getScene,
        setOrbit,
        toggleHelpers,
        refreshHelpers,
    } = useThreeMainScene({
        id: 'MainScene',
        enableOrbit: isSandbox && params.orbit,
        enableHelpers: isSandbox && params.helpers,
        composer: sceneComposer,
        assets: {
            gltf: '/glb/Fanatic_Animation_Export.glb',
        },
        cbSceneReady: (data) => {
            setupScene(data);
            setupComposer(data);

            camera.position.set(0, 0, 11); // temp

            sceneCamera.value = data.camera;

            // Reset animations and play the current step
            setCameraOffset();
            reset();
            onStep(step.value);
        },
    });

    const setupComposer = ({ scene, camera }) => {
        sceneComposer.setup(renderer, scene, camera, orthoCamera);

        const { composer } = sceneComposer;

        const last = (arr) => arr[arr.length - 1];
        const renderLastEnabled = () => {
            composer.passes.forEach((pass) => {
                pass.renderToScreen = pass === last(composer.passes.filter((pass) => pass.enabled));
            });
        };

        if (pane) {
            const folder = pane?.addFolder({ title: 'Passes' });
            composer?.passes.forEach((pass) => {
                folder
                    ?.addBinding(pass, 'enabled', { label: pass.name })
                    .on('change', () => renderLastEnabled());
            });
        }
    };

    const setupScene = ({ scene, animationsMap, assets }) => {
        // update components
        const objectsCustom = [];
        scene.traverse((object) => {
            // getAllMeshes(scene).forEach((object) => {
            // const map = object.material.map;
            // object.material.dispose();
            if (object.name === 'box') refBox.value?.init({ object });
            if (object.name === 'pack') refPack.value?.init({ object });
            if (object.name === 'cards') {
                object.children.forEach((card) => (card.visible = false));
                // refCards.value?.addToParent(object);
            }

            const customComp = componentTypes.find((item) => item.id === object.name);
            if (customComp) {
                const { id, idAnims } = customComp;
                objectsCustom.push({
                    ...customComp,
                    props: {
                        object,
                        animations: animationsMap.filter((_) =>
                            _.name.includes((idAnims || id).toLowerCase())
                        ),
                    },
                });
            }

            object.renderOrder = object.userData['renderorder'] || object.renderOrder;
        });

        if (params.helpers) nextTick().then(() => refreshHelpers());

        components.value = objectsCustom;
    };

    let stepCurr;
    const playAnimationsByStep = (stepPlay) => {
        const animsTypeReplace = ['box_parent_', 'camera_', 'pack_'];
        const animsDiscard = ['pack_card3_step3'];
        const animsHold = [
            'camera_step2',
            'camera_parent_step2',
            'box_parent_step2',
            'box_mesh_step2',
            'cards_step2',
            'pack_step2',
        ];

        const anims = getAnimations().filter(({ name }) => !animsDiscard.includes(name));

        const getStepNumber = (step) => {
            const stepNumber = (step || 'step1').replace('step', '');
            return parseInt(stepNumber);
        };

        anims
            .filter(({ name }) => !animsDiscard.includes(name))
            .map((animation) => {
                animsTypeReplace.forEach((type) => {
                    // replace if the animation is before the current step
                    if (animation.name.includes(type)) animation.action.setEffectiveWeight(0);
                });

                animsHold.forEach((name) => {
                    if (
                        animation.name == name &&
                        getStepNumber(stepPlay) > getStepNumber(stepCurr)
                    ) {
                        // hold if the animation and go to previous end step
                        animation.action.time = animation.duration;
                        animation.action.setEffectiveWeight(1).play();
                    }
                });
                // replace if the animation is before the current step
                if (getStepNumber(stepPlay) < getStepNumber(stepCurr))
                    animation.action.setEffectiveWeight(0);

                return animation;
            })
            .filter(({ name }) => name.includes(stepPlay))
            .map((animation) => {
                animation.action.setEffectiveWeight(1).reset().play();
                return animation;
            });
        anims.forEach(({ name, action }) => {
            // console.log(name, action.getEffectiveWeight());
        });

        stepCurr = stepPlay;
    };

    const setCameraOffset = () => {
        if (!sceneCamera.value) return;

        const { width, height } = viewport;

        const x = 0;
        const y = sceneOffset.value;
        sceneCamera.value.setViewOffset(width, height, x, y, width, height);
        sceneCamera.value.updateProjectionMatrix();
    };

    const viewport = useViewportResize(() => {
        requestAnimationFrame(() => {
            const dpr = Math.min(renderer.getPixelRatio(), 2);
            renderer.setPixelRatio(dpr);

            setCameraOffset();
        });
    }, true);

    const onStep = (val, prevVal) => {
        const hideBox = val == StepPackReveal && prevVal !== StepBoxReveal;

        refBox.value.visible = !hideBox;

        switch (val) {
            case StepIdle:
                reset();
                break;
            case StepBoxReveal:
                playAnimationsByStep('step1');
                refBox.value?.show();
                if (params.helpers) refreshHelpers();

                gsap.delayedCall(0.1, () => audio.play('boxOpen'));

                break;
            case StepPackReveal:
                refBox.value?.open({
                    cbComplete: () => (params.helpers ? refreshHelpers() : null),
                });

                refPack.value?.show({ delay: 1, useFog: hideBox });

                playAnimationsByStep('step2');

                audio.play('boxReveal');
                break;
            case StepCardsReveal:
                playAnimationsByStep('step3');

                audio.play('packDisolve');

                refPack.value?.open({ delay: 0.1 });
                refCards.value?.show({ delay: 1.2 });
                break;

            default:
                break;
        }
    };
    const onCardIndex = (val) => {
        if (step.value === StepCardsReveal) audio.play('nextPrev');
    };

    const onComplete = () => {
        refCards.value?.hide();

        audio.play('fadeAway');
    };

    const render = () => {
        const scene = getScene();
        renderScene();
    };
    useRaf(render);

    const resetAnimations = () => {
        stepCurr = null;
        getAnimations().forEach(({ action }) => action.setEffectiveWeight(0).reset().stop());
    };

    const reset = () => {
        refPack.value?.reset();
        refBox.value?.reset();

        resetAnimations();
    };

    watch(step, onStep);
    watch(cardIndex, onCardIndex);

    defineExpose({
        reset,
    });

    const pane = usePane(
        [
            {
                value: params,
                options: {
                    onChange: (ev) => {
                        if (ev.target.key == 'orbit') setOrbit(ev.value);
                        if (ev.target.key == 'helpers') toggleHelpers(ev.value);
                    },
                },
            },
        ],
        { title: 'Scene', expanded: true }
    );
</script>
